import React, { Component } from 'react'
import Overlay from "../Overlay";
import SimpleTable from "../tables/SimpleTable";
import FilterCard, { FIELD_TYPE, ReactSelect } from "../FilterCard";
import moment from "moment";
import {
  getLabel,
  getNumPages,
  getPageOptions,
  showModalNoOutsideClick,
  slicePages,
} from "../../util/FormatUtil";
import { sweetalert } from "../../App";
import Select from "react-select";
import ExemptionRecordModal from '../modals/ExemptionRecordModal';
import NetworkUtil from "../../network/NetworkUtil";
import { Column } from "../tables/TableBase";
import { Sorter, TableOrder } from "../../sorting/Sorter";
import PaginationTool from "../PaginationTool";
import SystemAPI from "../../network/SystemAPI";
import ExemptionAPI from '../../network/ExemptionAPI';
import VaccineExemptionCertificate from '../resultReports/VaccineExemptionCertificate';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

const ITEMS_PER_PAGE = 25;

interface ExemptionRecordsState {
  showLoading: boolean;
  selectedExemption;
  exemptionQueryResults;
  tableData: {}[];
  filter
  direction?: TableOrder;
  page_options: ReactSelect[];
  selected_page?: { label; value }
  filteredObj?
  totalExemptions?
  searched?
  immunizations?
  showCertificate?
  races?
  genders?
  branding?
  exemptionTypes?
  states
}


interface ExemptionRecordsProps {
  t
}

class ExemptionRecords extends Component<
  ExemptionRecordsProps,
  ExemptionRecordsState
> {
  private exemptionFilterTabsRef: any;
  constructor(props) {
    super(props);

    this.state = {
      showLoading: false,
      selectedExemption: null,
      tableData: [] as any,
      exemptionQueryResults: [] as any,
      filter: {},
      states: [],
      direction: "asc",
      page_options: [{ label: "1", value: "1" }],
      selected_page: { label: "1", value: 1 },
    };

    this.exemptionFilterTabsRef = React.createRef();
  }

  componentDidMount(): void {
    document.title = 'View Vaccine Exemption Records | Oklahoma Department of Health'
    SystemAPI.getAllGenders().then(data => {
      this.setState({ genders: data })
    })
    SystemAPI.getAllRaces().then(data => {
      this.setState({ races: data })
    })
    SystemAPI.getAllStates().then(data => {
      this.setState({ states: data })
    })
    SystemAPI.getAllImmunizations().then(data => {
      this.setState({ immunizations: data })
    })
    SystemAPI.getProductBrandingFromURL(window.location.href).then(data => {
      this.setState({ branding: data })
    })
    SystemAPI.getAllExemptionTypes().then(data => {
      this.setState({ exemptionTypes: data })
    })

    this.setState({ showLoading: true }, async () => {
      let results = await ExemptionAPI.filterExemptions({
        filter: {
          ExemptionType: this.state.filter.ExemptionType,
          ChildFirstName: this.state.filter.ChildFirstName,
          ChildLastName: this.state.filter.ChildLastName
        },
      });
      if (!results.success) {
        return sweetalert.fire({
          title: "",
          html: `<p aria-live='polite'>${this.props.t("Unable to filter Results data at this time")}</p>`,
          icon: "error",
        });
      }
      if (results.data[0].length < 1) {
        this.setState({ showLoading: false })
        return sweetalert.fire({
          title: "",
          html: `<p aria-live='polite'>${this.props.t("No Records found")}</p>`,
          icon: "error",
        });
      }
      this.setState({
        tableData: slicePages(results.data[0], 1, ITEMS_PER_PAGE),
        exemptionQueryResults: results.data[0],
        page_options: getPageOptions(getNumPages(results.data[0], ITEMS_PER_PAGE)),
        showLoading: false,
      });
    });
  }

  queryExemptionData(page: number) {
    this.setState({ showLoading: true, tableData: [] }, async () => {
      let results = await ExemptionAPI.filterExemptions({
        filter: {
          ExemptionType: this.state.filter.ExemptionType,
          ChildFirstName: this.state.filter.ChildFirstName,
          ChildLastName: this.state.filter.ChildLastName,
          SchoolYear: this.state.filter.SchoolYear
        },
      });
      if (!results.success) {
        return sweetalert.fire({
          title: "",
          text: this.props.t("Unable to filter Results data at this time"),
          icon: "error",
        });
      }
      if (results.data[0].length < 1) {
        this.setState({ showLoading: false })
        return sweetalert.fire({
          title: "",
          html: `<p aria-live='polite'>${this.props.t("No Records found")}</p>`,
          icon: "error",
        });
      }
      this.setState({
        tableData: slicePages(results.data[0], page, ITEMS_PER_PAGE),
        exemptionQueryResults: results.data[0],
        page_options: getPageOptions(getNumPages(results.data[0], ITEMS_PER_PAGE)),
        showLoading: false,
      });
    });
  }

  handleExportToCSV() {
    this.setState({ showLoading: true }, async () => {
      await NetworkUtil.downloadCSV(
        "/api/admin/results/csv",
        "Result Management Data.xlsx",
        { filter: this.state.filter });
      this.setState({ showLoading: false });
    });
  }

  changePage(page: number) {
    let allServices = this.state.exemptionQueryResults;
    let returnData = slicePages(allServices, page, ITEMS_PER_PAGE);
    this.setState({ tableData: returnData });
  }

  useSorter(col: Column) {
    let sorter = new Sorter<any>();

    this.setState({
      tableData: sorter.sortByKey(
        this.state.tableData,
        col.key as keyof any,
        this.state.direction
      ),
      direction: this.state.direction === "asc" ? "desc" : "asc",
    });
  }

  renderExemptionsFilterFields() {
    return (
      <FilterCard
        fields={[
          {
            label: this.props.t("Exemption Type"),
            key: "ExemptionType",
            type: FIELD_TYPE.SELECT,
            options: this.state.exemptionTypes ? this.state.exemptionTypes.map((e) => ({ label: this.props.t(e.label), value: e.value })) : [],
            isMapped: true,
            isMulti: true,
            textType: "text",
          },
          {
            label: this.props.t("Child First Name"),
            key: "ChildFirstName",
            type: FIELD_TYPE.TEXT,
            textType: "text",
          },
          {
            label: this.props.t("Child Last Name"),
            key: "ChildLastName",
            type: FIELD_TYPE.TEXT,
            textType: "text",
          },
          {
            label: this.props.t("School Year"),
            key: "SchoolYear",
            type: FIELD_TYPE.TEXT,
            textType: "text",
          },
        ]}
        t={this.props.t}
        filterChanged={(e) => this.setState({ filter: e })}
      />
    );
  }

  render() {
    return (
      <React.Fragment>
        <PaginationTool />
        <Overlay show_loading={this.state.showLoading} />
        <VaccineExemptionCertificate
          data={this.state.selectedExemption}
          exemptImmunizations={this.state.selectedExemption ? JSON.parse(this.state.selectedExemption?.ExemptImmunizations) : null}
          immunizations={this.state.immunizations}
          genders={this.state.genders}
          races={this.state.races}
          branding={this.state.branding}
        />
        <ExemptionRecordModal
          selectedExemption={this.state.selectedExemption}
          immunizations={this.state.immunizations}
          onClose={() => this.setState({ selectedExemption: null })}
          states={this.state.states}
        />

        <div className="container-fluid ">
          <main id="main-content" tabIndex={-1} aria-label={this.props.t("Vaccine Exemptions")} className="row pt-2 m-1">
            <div className="col-12 col-md-12 col-lg-7 col-xl-7">
              <div className="card mb-2">
                <div className="card-header verlag-bold">
                  <h3>{this.props.t("Exemption Records")}</h3>
                </div>
                <div className="card-body">
                  {this.renderExemptionsFilterFields()}
                </div>
                <div className="card-footer">
                  <button
                    className={"btn immySubmitButtonOutline"}
                    onClick={() => {
                      this.setState(
                        {
                          selected_page: { label: 1, value: 1 },
                        },
                        () => {
                          this.queryExemptionData(
                            this.state.selected_page.value
                          );
                        }
                      );
                    }}
                  >
                    {this.props.t("Search")}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-5 col-xl-5">
              <div className="card mb-2">
                <div className="card-body">
                  <p>{this.props.t("Parents/Guardians are responsible for providing the approved exemption certificate to their child's school, child care facility or Head Start program.")}</p>
                  <p><span style={{ textDecoration:'underline'}}>{this.props.t("To download and print the certificate:")}</span></p>
                  <ol>
                    <li>{this.props.t("Select the View Certificate option for exemption with an Approved Status")}</li>
                    <li>{this.props.t("The approved exemption certificate will display on the screen")}</li>
                    <li>{this.props.t("Select the green Download or Print option on the top left of the certificate")}</li>
                  </ol>
                </div>
              </div>
            </div>
          </main>

          <div className="col-12 col-md-12 col-lg-12 pt-2">
            {this.state.tableData && this.state.tableData.length > 0 && (
              <div className="card mt-2 mb-5">
                <div className="card-header verlag-bold stickToTop" style={{ zIndex: 2 }}>
                  <h4 className="text-center text-md-left">
                    {this.props.t("Exemptions")}  <span style={{color:'red', fontSize:'medium'}}>*{this.props.t("After status is changed to Approved or Denied, select View Certificate. Download or Print option will be displayed on top left of Certificate.")}</span>
                    <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                      <h4 className={"float-md-right"} aria-label="Total Records" role="alert">
                        {this.props.t("Total")}: {(this.state.exemptionQueryResults).length}
                      </h4>
                      <h4 className="d-inline-block float-right align-middle pr-2 ml-5">
                        {this.props.t("Page")}{" "}
                      </h4>
                      <div className=" align-middle float-right pages ">
                        <Select
                          isSearchable={true}
                          placeholder={"1"}
                          noOptionsMessage={() => this.props.t("No option")}
                          value={this.state.selected_page}
                          aria-label={this.props.t('Table Page Number')}
                          onChange={(e: ReactSelect) =>
                            this.setState({ selected_page: e }, () =>
                              this.changePage(e.value)
                            )
                          }
                          className={"state_select"}
                          options={this.state.page_options}
                        />
                      </div>
                    </section>
                  </h4>
                </div>
                <div className="card-body p-0 m-0 table-responsive">
                  <SimpleTable
                    columns={[
                      {
                        label: this.props.t("Name"),
                        key: "Name",
                        popoverText: this.props.t("Click to Sort By Name"),
                        rawFormat: (val) => {
                          return (
                            <a
                              href={"#"}
                              className={'tableNameLinkColor'}
                              onClick={() => {
                                this.setState({ selectedExemption: JSON.parse(JSON.stringify(val)) }, () =>
                                  showModalNoOutsideClick(
                                    "ExemptionRecordModal"
                                  )

                                );
                              }}
                            >
                              {val.ChildMiddleInitial ? val.ChildFirstName + " " + val.ChildMiddleInitial + " " + val.ChildLastName : val.ChildFirstName + " " + val.ChildLastName}
                            </a>
                          );
                        },
                      },
                      {
                        label: this.props.t("Exemption Type"),
                        key: "ExemptionType",
                        popoverText: this.props.t("Click to Sort By Exemption Type"),
                        rawFormat: (val) => {
                          return <p>{getLabel(val.ExemptionType, this.state.exemptionTypes, this.props.t)?.label}</p>;
                        }
                      },
                      {
                        label: this.props.t("Status"),
                        key: "Status",
                        popoverText: this.props.t("Click to Sort By Status"),
                        rawFormat: (val) => {
                          return <p>{val.Status && new Date() > new Date(val.PatientNotifiedDate) ? this.props.t("Approved") : val.Status == null || new Date() < new Date(val.PatientNotifiedDate) ? this.props.t("Pending") : this.props.t("Denied")}</p>;
                        }
                      },
                      {
                        label: this.props.t("Submitted Date"),
                        key: "Status",
                        popoverText: this.props.t("Click to Sort By Status"),
                        rawFormat: (val) => {
                          return <p>{moment(val?.CreatedDate).utc().format("MM-DD-YYYY")}</p>;
                        }
                      },
                      {
                        label: this.props.t("Certificate"), key: "",
                        rawFormat: (val) => (
                          <button
                            type={'button'}
                            className={'btn btn-outline-primary'}
                            style={{ zIndex: 1 }}
                            onClick={() => { this.setState({ selectedExemption: val }, () => showModalNoOutsideClick(VaccineExemptionCertificate.ID)) }}
                            disabled={val.Status == null || new Date() < new Date(val.PatientNotifiedDate)}
                          >
                            {this.props.t("View Certificate")}
                          </button>)
                      }
                    ]}
                    table_data={this.state.tableData}
                    columnClickedCallback={(col) => {
                      this.useSorter(col);
                    }}
                  />
                </div>
              </div>
            )}
          </div>

        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(ExemptionRecords);
